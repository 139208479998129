import React, { Component } from 'react';
import emergencyImage from '../../image/up_emergency_bg.png';
import emergencyMobileImage from '../../image/up_emergency_bg_mobile.png';

class MaintainPlanEmergencyDom extends Component {
  render() {
    return (
      <div id="mainBody" >
        <div className="img-box-d">
          <div className="img-box">
            <img src={emergencyImage} className="desktop" alt=""/>
            <img src={emergencyMobileImage} className="mobile" alt=""/>
          </div>
          <div className="txt-box">
            <div className="title">系统繁忙中</div>
            <div className="text text-content">
              <div className="style01">正为您火速处理中，请稍后再试</div>
              <div className="style02">System is busy, please try again later.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MaintainPlanEmergencyDom;
