import React, { Component } from 'react';
import MaintainPlanIntegrationDom from '../../Components/Bbin/MaintainPlanIntegrationDom';
import { callGetMaintainPlanDisplayData } from '../../api/maintainPlanView';
import MaintainPlanEmergencyDom from '../../Components/Bbin/MaintainPlanEmergencyDom';
import MaintainPlan from '../../Common/MaintainPlan';

class MaintainPlanIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMaintaining: false,
      startTime: '',
      endTime: '',
    };
  }

  componentDidMount() {
    callGetMaintainPlanDisplayData().then((json) => {
      this.setState({
        ...this.state,
        isMaintaining: true,
        startTime: json.start,
        endTime: json.end,
      });
    });
  }

  render() {
    const getTime = {
      isMaintaining: this.state.isMaintaining,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
    };
    return (
      MaintainPlan.isMaintaining(this.state.startTime, this.state.endTime)
        ? <MaintainPlanIntegrationDom getIntegartionTime = { getTime }/>
        : <MaintainPlanEmergencyDom/>
    );
  }
}

export default MaintainPlanIntegration;
