import axios from 'axios';
import alertify from 'alertify-js';
import HttpStatusCode from '../Common/HttpStatusCode';
import MaintainPlan from '../Common/MaintainPlan';

/**
 * 檢查 Plans API欄位是否存在
 * @return boolean
 */
export const confirmPlanField = (response) => {
  // 確認 維護計劃名稱 欄位是否存在
  if ('name' in response === false) {
    return false;
  }

  // 確認 維護計劃頁面樣式 欄位是否存在
  if ('style' in response === false) {
    return false;
  }

  // 確認 維護計劃開始時間 欄位是否存在
  if ('start' in response === false) {
    return false;
  }

  // 確認 維護計劃結束時間 欄位是否存在
  if ('end' in response === false) {
    return false;
  }

  // 確認 維護計劃狀態 欄位是否存在
  if ('active' in response === false) {
    return false;
  }

  return true;
};

/**
 * 呼叫`取得顯示維護項目`API
 * @return {Promise<AxiosResponse<T> | never>}
 */
export const callGetMaintainPlanDisplayData = () => {
  const { href } = window.location;
  // 取得位於 'maintain_plan/' 字串後的 id
  const location = href.substring(href.indexOf('maintain_plan/') + 14, href.length);
  const url = `api/plan_name/${location}`;
  return axios.get(url)
    .then((response) => {
      // HTTP 狀態碼非 200 ，顯示未知錯誤訊息
      if (response.status !== HttpStatusCode.STATUS_200_OK) {
        alertify.log('未知錯誤訊息', 'error', 0);
        return {};
      }

      // 檢查 Plan API欄位是否存在
      if (confirmPlanField(response.data) === false) {
        alertify.log('API欄位不存在', 'error', 0);
        return {};
      }
      return MaintainPlan.parseDateFormat(response).data;
    })
    .catch((err) => {
      // HTTP 狀態碼非 404 ，顯示網頁發生錯誤
      if (err.status !== HttpStatusCode.STATUS_404_NOT_FOUND) {
        alertify.log('網頁發生錯誤', 'error', 0);
        return {};
      }

      // 判別 error 欄位是否存在
      if ('error' in err === false) {
        alertify.log('未知的錯誤 (無"error"欄位)', 'error', 0);
        return {};
      }

      // HTTP 狀態碼為 404 ，顯示無此維護計劃
      alertify.log('無此維護計劃', 'error', 0);
      return {};
    });
};
