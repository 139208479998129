import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

const BbinCss = lazy(() => import('./BbinCss.js'));

class PlatformCssSelector extends Component {
  render() {
    const { component: PropsComponent, ...rest } = this.props;
    const renderHandler = (props => (<PropsComponent {...props} />));
    return (
      <>
        <Suspense fallback={<></>}>
          <BbinCss />
        </Suspense>
        <React.Fragment { ...rest } render = { renderHandler }/>
      </>
    );
  }
}

PlatformCssSelector.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PlatformCssSelector;
