import React from 'react';
import ReactDOM from 'react-dom';
import MaintainPlan from './MaintainPlan';
import PlatformCssSelector from './css/PlatformCssSelector';
import './css/maintainPlan.css';
import './css/alertify/alertify.default.css';
import './css/alertify/alertify.core.css';

ReactDOM.render(
  <React.StrictMode>
    <PlatformCssSelector>
      <MaintainPlan />
    </PlatformCssSelector>
  </React.StrictMode>,
  document.getElementById('root'),
);
