class HttpStatusCode {
  static STATUS_0_NONE = 0;

  static STATUS_1_UNKNOWN = 1;

  static STATUS_200_OK = 200;

  static STATUS_201_CREATED = 201;

  static STATUS_202_ACCEPTED = 202;

  static STATUS_203_NON_AUTHORITATIVE_INFORMATION = 203;

  static STATUS_204_NO_CONTENT = 204;

  static STATUS_205_RESET_CONTENT = 205;

  static STATUS_206_PARTIAL_CONTENT = 206;

  static STATUS_207_MULTI_STATUS = 207;

  static STATUS_300_MULTIPLE_CHOICES = 300;

  static STATUS_301_MOVED_PERMANENTLY = 301;

  static STATUS_302_FOUND = 302;

  static STATUS_303_SEE_OTHER = 303;

  static STATUS_304_NOT_MODIFIED = 304;

  static STATUS_305_USE_PROXY = 305;

  static STATUS_306_SWITCH_PROXY = 306;

  static STATUS_307_TEMPORARY_REDIRECT = 307;

  static STATUS_400_BAD_REQUEST = 400;

  static STATUS_401_UNAUTHORIZED = 401;

  static STATUS_402_PAYMENT_REQUIRED = 402;

  static STATUS_403_FORBIDDEN = 403;

  static STATUS_404_NOT_FOUND = 404;

  static STATUS_405_METHOD_NOT_ALLOWED = 405;

  static STATUS_406_NOT_ACCEPTABLE = 406;

  static STATUS_407_PROXY_AUTHENTICATION_REQUIRED = 407;

  static STATUS_408_REQUEST_TIMEOUT = 408;

  static STATUS_409_CONFLICT = 409;

  static STATUS_410_GONE = 410;

  static STATUS_411_LENGTH_REQUIRED = 411;

  static STATUS_412_PRECONDITION_FAILED = 412;

  static STATUS_413_REQUEST_ENTITY_TOO_LARGE = 413;

  static STATUS_414_REQUEST_URI_TOO_LONG = 414;

  static STATUS_415_UNSUPPORTED_MEDIA_TYPE = 415;

  static STATUS_416_REQUESTED_RANGE_NOT_SATISFIABLE = 416;

  static STATUS_417_EXPECTATION_FAILED = 417;

  static STATUS_418_IM_A_TEAPOT = 418;

  static STATUS_421_THERE_ARE_TOO_MANY_CONNECTIONS_FROM_YOUR_INTERNET_ADDRESS = 421;

  static STATUS_422_UNPROCESSABLE_ENTITY = 422;

  static STATUS_423_LOCKED = 423;

  static STATUS_424_FAILED_DEPENDENCY = 424;

  static STATUS_425_UNORDERED_COLLECTION = 425;

  static STATUS_426_UPGRADE_REQUIRED = 426;

  static STATUS_449_RETRY_WITH = 449;

  static STATUS_500_INTERNAL_SERVER_ERROR = 500;

  static STATUS_501_NOT_IMPLEMENTED = 501;

  static STATUS_502_BAD_GATEWAY = 502;

  static STATUS_503_SERVICE_UNAVAILABLE = 503;

  static STATUS_504_GATEWAY_TIMEOUT = 504;

  static STATUS_505_HTTP_VERSION_NOT_SUPPORTED = 505;

  static STATUS_506_VARIANT_ALSO_NEGOTIATES = 506;

  static STATUS_507_INSUFFICIENT_STORAGE = 507;

  static STATUS_509_BANDWIDTH_LIMIT_EXCEEDED = 509;

  static STATUS_510_NOT_EXTENDED = 510;
}

export default HttpStatusCode;
