class MaintainPlan {
  static parseDateFormat = response => ({
    data: {
      ...response.data,
      start: response.data.start.replace(/-/g, '/'),
      end: response.data.end.replace(/-/g, '/'),
    },
  });

  /**
   * 判斷維護計劃是否維護中
   * @param startDatetime `0000-00-00 00:00:00`
   * @param endDatetime  `0000-00-00 00:00:00`
   * @returns {boolean}
   */
  static isMaintaining = (startDatetime, endDatetime) => {
    // 獲取目前時間 unix timestamp
    const nowTimeStamp = Math.floor(Date.now() / 1000);

    // 將設定的開始維護時間，轉為 unix timestamp
    let startTimeStamp = new Date(startDatetime).getTime() / 1000;
    // 瀏覽器時間格式為 `0000/00/00 00:00:00`
    if (!startTimeStamp) {
      startTimeStamp = new Date(startDatetime.replace(/-/g, '/')).getTime() / 1000;
    }

    // 將設定的結束維護時間，轉為 unix timestamp
    let endTimeStamp = new Date(endDatetime).getTime() / 1000;
    // 瀏覽器時間格式為 `0000/00/00 00:00:00`
    if (!endTimeStamp) {
      endTimeStamp = new Date(endDatetime.replace(/-/g, '/')).getTime() / 1000;
    }

    // 判斷維護計劃是否維護中
    return ((nowTimeStamp >= startTimeStamp) && (endTimeStamp >= nowTimeStamp));
  };

  /**
   * 依照使用者瀏覽器語系顯示預設維護畫面(緊急維護畫面)
   * @return {string}
   */
  static getEmergencyContentPerLocalLang = () => {
    const localLanguage = window.navigator.language.toLowerCase();
    let content = '';
    switch (localLanguage) {
      case 'zh-tw':
      case 'zh-hk':
        content = '系統繁忙中，請稍後再試。';
        break;
      case 'zh':
      case 'zh-cn':
        content = '系统繁忙中，请稍后再试。';
        break;
      case 'ko':
      case 'ko-kr':
        content = '시스템 점검 중입니다. 잠시 후에 다시 시도하십시오.';
        break;
      case 'vi':
      case 'vi-vn':
        content = 'Hệ thống bận, hãy đợi thử sau';
        break;
      case 'th':
      case 'th-th':
        content = 'ระบบไม่ว่าง กรุณารอสักครู่ค่อยลองใหม่';
        break;
      case 'ja':
      case 'ja-jp':
        content = 'ただいまシステムが混み合っております。<br>しばらく時間をおいてからもう一度お試しください。';
        break;
      default:
        content = 'System is busy, try again later.';
        break;
    }
    return content;
  };

  /**
   * 轉換成當地時區的字串
   * @param dateTime
   * @return {string}
   */
  static convertLocaleString = (dateTime) => {
    const local = new Date(`${dateTime} GMT+8`);
    const year = MaintainPlan.padZero(local.getFullYear());
    const month = MaintainPlan.padZero(local.getMonth() + 1);
    const date = MaintainPlan.padZero(local.getDate());
    const hour = MaintainPlan.padZero(local.getHours());
    const minute = MaintainPlan.padZero(local.getMinutes());
    const second = MaintainPlan.padZero(local.getSeconds());

    const localDate = `${year}-${month}-${date}`;
    const localTime = `${hour}:${minute}:${second}`;

    return `${localDate} ${localTime}`;
  };

  /**
   * 依照使用者瀏覽器語系顯示維護時間內畫面
   * @return {Object}
   */
  static getMaintainPlanPerLocalLang = () => {
    const localLanguage = window.navigator.language.toLowerCase();
    let upgradeContent = '';
    let inconvenienceContent = '';
    let localTimeContent = '';
    let completeContent = '';
    switch (localLanguage) {
      case 'zh-tw':
      case 'zh-hk':
        upgradeContent = '目前網站進行系統升級中，';
        inconvenienceContent = '如有不便之處，敬請見諒！';
        localTimeContent = '預計於當地時間';
        completeContent = '完成';
        break;
      case 'zh':
      case 'zh-cn':
        upgradeContent = '目前网站进行系统维护中，';
        inconvenienceContent = '如有不便之处，敬请见谅！';
        localTimeContent = '预计完成于当地时间';
        completeContent = '完成';
        break;
      case 'ko':
      case 'ko-kr':
        upgradeContent = '현재 사이트가 점검중입니다,';
        inconvenienceContent = '사용에 불편을 드린점 많은 양해 부탁 드립니다.';
        localTimeContent = '현지 시간';
        completeContent = '완성할 예정입니다.';
        break;
      case 'vi':
      case 'vi-vn':
        upgradeContent = 'Hiện tại hệ thống đang bảo trì,';
        inconvenienceContent = 'xin vui lòng lượng thứ vì sự bất tiện này.';
        localTimeContent = 'Dự kiến ​​sẽ hoàn thành vào giờ địa phương ';
        break;
      case 'th':
      case 'th-th':
        upgradeContent = 'ขณะนี้ระบบกำลังทำการปรับปรุงใหม่ ';
        inconvenienceContent = 'ขออภัยในความไม่สะดวก และขอบคุณที่ให้ความเข้าใจ';
        localTimeContent = 'คาดว่าจะสำเร็จเวลา';
        completeContent = 'ตามเวลาท้องถิ่น';
        break;
      case 'ja':
      case 'ja-jp':
        upgradeContent = 'ただいまメンテナンス中です。';
        inconvenienceContent = 'ご迷惑をおかけして申し訳ございません。';
        localTimeContent = 'メンテナンスの終了予定は';
        completeContent = 'です。';
        break;
      default:
        upgradeContent = 'Access to the site is not available due to scheduled maintenance,';
        inconvenienceContent = 'we apologize for any inconvenience caused.';
        localTimeContent = 'The maintenance will be completed at';
        completeContent = '.';
        break;
    }
    return {
      upgradeContent,
      inconvenienceContent,
      localTimeContent,
      completeContent,
    };
  };

  /**
   * 取得當地時區的字串
   * @param dateTime
   * @return {string}
   */
  static getTimeZone = (dateTime) => {
    const local = new Date(`${dateTime} GMT+8`).toString();
    const timeZoneStart = local.indexOf('GMT');
    const timeZoneEnd = timeZoneStart + 6;

    return `${local.substring(timeZoneStart, timeZoneEnd)}:00`;
  };

  /**
   * 補齊0
   * @param number
   * @return {string}
   */
  static padZero = (number) => {
    const num = (number < 10) ? `0${number}` : number;
    return num;
  }
}

export default MaintainPlan;
