/* eslint-disable no-undef */
/* eslint-disable wrap-iife */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
import React, { Component } from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';
import MaintainPlanEmergencyDom from './Components/Bbin/MaintainPlanEmergencyDom';
import MaintainPlanEmergencyDom2 from './Components/Bbin/MaintainPlanEmergencyDom2';
import MaintainPlanIntegration from './Containers/Bbin/MaintainPlanIntegration';


class Router extends Component {
  componentDidMount() {
    ((i, s, o, g, r) => {
      i[r] =
        i[r] ||
        (() => {
          (i[r].q = i[r].q || []).push(arguments);
        });
      i.addEventListener('DOMContentLoaded', () => {
        const a = s.createElement(o);
        a.async = 1;
        a.src = g;
        a.type = 'text/javascript';
        s.body.appendChild(a);
      });
    })(window, document, 'script', 'https://js.agilenavi.com/na.js', 'na');

    na('send', 'pageview', 'pathway');
    na('create', 'CY-A09562DA', '@upup-bbin');
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          {/* bbin */}
          <Route exact path="/emergency" component={MaintainPlanEmergencyDom} />
          <Route
            exact
            path="/emergency2"
            component={MaintainPlanEmergencyDom2}
          />
          <Route path="/integration" component={MaintainPlanIntegration} />
        </Switch>
      </HashRouter>
    );
  }
}

export default Router;
