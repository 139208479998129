import React, { Component } from 'react';
import Router from './Router';

class MaintainPlan extends Component {
  render() {
    return (
      <Router/>
    );
  }
}

export default MaintainPlan;
