import React, { Component } from 'react';
import PropTypes from 'prop-types';
import integrationImage from '../../image/up_integration_bg.png';
import integrationMobileImage from '../../image/up_integration_bg_mobile.png';
import bbinLogo from '../../image/bbinlogo.png';

class MaintainPlanIntegrationDom extends Component {
  render() {
    return (
      <div id="mainBody">
        <div className="img-box-d">
          <div className="img-box">
            <img src={integrationImage} className="desktop" alt=""/>
            <img src={integrationMobileImage} className="mobile" alt=""/>
            <div className="bbinlogo"><img src={bbinLogo} alt=""/></div>
          </div>
          <div className="txt-box">
            <div className="title">网站系统公告</div>
            <div className="text text-content">
              <span>Access to the site is not available </span>
              <span>due to scheduled maintenance, </span>
              <span className="block-md">we apologize for any inconvenience caused.</span>
            </div>
            <div className="text text-time">
              <span>维护时间（北京时间）：</span>
              <span className="block-xs time-style">
                { this.props.getIntegartionTime.startTime } ~ { this.props.getIntegartionTime.endTime }
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MaintainPlanIntegrationDom.propTypes = {
  getIntegartionTime: PropTypes.shape({
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
  }).isRequired,
};

export default MaintainPlanIntegrationDom;
